<template id="optasetup">
  <b-container fluid>
    <b-card no-body v-if="team" class="mb-2">
      <b-row align-v="center" class="mt-2">
        <b-col cols="4" class="text-center">
          <h1>
            {{
              game.team_home.squadra
                ? game.team_home.squadra.name
                : game.team_home.name
            }}
          </h1>
        </b-col>
        <b-col cols="4" class="text-center">
          <h1 v-if="game">
            <b-img
              :src="
                game.team_home.squadra
                  ? game.team_home.squadra.logo_url
                  : '/assets/images/team.png'
              "
              fluid
              :width="70"
            ></b-img>
            {{ game.score_home }} - {{ game.score_away }}
            <b-img
              :src="
                game.team_away.squadra
                  ? game.team_away.squadra.logo_url
                  : '/assets/images/team.png'
              "
              fluid
              :width="70"
            ></b-img>
          </h1>
          <p class="text-muted" style="font-size: 1.3rem" v-if="game">
            {{ game.date | formatDate }} -
            {{ game.league ? game.league.name : "" }}
          </p>
        </b-col>
        <b-col cols="4" class="text-center">
          <h1>
            {{
              game.team_away.squadra
                ? game.team_away.squadra.name
                : game.away_home.name
            }}
          </h1>
        </b-col>
      </b-row>
    </b-card>

    <b-row align-v="top" class="my-0" v-if="lineup">
      <b-col cols="4" class="text-center">
        <h5>Formation: {{ lineup[0].formationUsed }}</h5>
        <b-card class="mb-2 py-1" no-body>
          <b-row
            v-for="player in lineup[0].player.slice(0, 11)"
            :key="player.playerId"
          >
            <b-col>{{ player.shirtNumber }} - {{ player.matchName }}</b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="4" class="text-center">
        <h5>Substitution</h5>
        <b-card no-body class="py-1">
          <b-row>
            <b-col>
              <b-row
                v-for="sub in substitute.filter(
                  (item) => item.contestantId == game.team_home.teamId
                )"
                :key="sub.playerOnId"
                ><b-col
                  >{{ sub.timeMin }}' - {{ sub.playerOnName }}
                  <b-icon icon="arrow-up" variant="success"></b-icon>
                  {{ sub.playerOffName }}
                  <b-icon icon="arrow-down" variant="danger"></b-icon></b-col
              ></b-row>
            </b-col>
            <b-col>
              <b-row
                v-for="sub in substitute.filter(
                  (item) => item.contestantId == game.team_away.teamId
                )"
                :key="sub.playerOnId"
                ><b-col
                  >{{ sub.timeMin }}' - {{ sub.playerOnName }}
                  <b-icon icon="arrow-up" variant="success"></b-icon>
                  {{ sub.playerOffName }}
                  <b-icon icon="arrow-down" variant="danger"></b-icon></b-col
              ></b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="4" class="text-center">
        <h5>Formation: {{ lineup[1].formationUsed }}</h5>
        <b-card class="mb-2 py-1" no-body>
          <b-row
            v-for="player in lineup[1].player.slice(0, 11)"
            :key="player.playerId"
          >
            <b-col>{{ player.shirtNumber }} - {{ player.matchName }}</b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row align-v="top" class="my-0">
      <b-col cols="4" class="text-center">
        <h5>Player Ranking</h5>
        <b-card class="mb-2 pt-1" no-body>
          <h6>{{ getSkillById(5).label }}</h6>
          <b-row
            class="mb-2"
            v-for="item in ranking_data_home(5)"
            :key="item.id"
            ><b-col class="text-center">{{ item.player }}</b-col
            ><b-col class="text-center"
              ><div :style="'background:' + item.color" class="box-small">
                {{ item.value }}
              </div></b-col
            ></b-row
          >
        </b-card>
        <b-card no-body>
          <h6>{{ getSkillById(7).label }}</h6>
          <b-row
            class="mb-2"
            v-for="item in ranking_data_home(7)"
            :key="item.id"
            ><b-col class="text-center">{{ item.player }}</b-col
            ><b-col class="text-center"
              ><div :style="'background:' + item.color" class="box-small">
                {{ item.value }}
              </div></b-col
            ></b-row
          >
        </b-card>
      </b-col>
      <b-col cols="4" class="text-center">
        <h5>Team Stats</h5>
        <b-card no-body>
          <b-row align-v="center" class="my-2"
            ><b-col
              ><div
                :style="
                  'cursor:pointer;background:' +
                  getBgStatCoach(6, getValueSkillCoachById(6, true), true)
                "
                class="box-small"
                @click="showRangeGames(6, true)"
              >
                {{ getValueSkillCoachById(6, true) }}
              </div></b-col
            ><b-col cols="6">{{ getSkillCoachById(6).label }}</b-col
            ><b-col
              ><div
                :style="
                  'cursor:pointer;background:' +
                  getBgStatCoach(6, getValueSkillCoachById(6))
                "
                class="box-small"
                @click="showRangeGames(6)"
              >
                {{ getValueSkillCoachById(6) }}
              </div></b-col
            ></b-row
          >
          <b-row align-v="center" class="mb-2"
            ><b-col
              ><div
                :style="
                  'cursor:pointer;background:' +
                  getBgStatCoach(7, getValueSkillCoachById(7, true), true)
                "
                class="box-small"
                @click="showRangeGames(7, true)"
              >
                {{ getValueSkillCoachById(7, true) }}
              </div></b-col
            ><b-col cols="6">{{ getSkillCoachById(7).label }}</b-col
            ><b-col
              ><div
                :style="
                  'cursor:pointer;background:' +
                  getBgStatCoach(7, getValueSkillCoachById(7))
                "
                class="box-small"
                @click="showRangeGames(7)"
              >
                {{ getValueSkillCoachById(7) }}
              </div></b-col
            ></b-row
          >
          <b-row align-v="center" class="mb-2"
            ><b-col
              ><div
                :style="
                  'cursor:pointer;background:' +
                  getBgStatCoach(237, getValueSkillCoachById(237, true), true)
                "
                class="box-small"
                @click="showRangeGames(237, true)"
              >
                {{ getValueSkillCoachById(237, true) }}
              </div></b-col
            ><b-col cols="6">{{ getSkillCoachById(237).label }}</b-col
            ><b-col
              ><div
                :style="
                  'cursor:pointer;background:' +
                  getBgStatCoach(237, getValueSkillCoachById(237))
                "
                class="box-small"
                @click="showRangeGames(237)"
              >
                {{ getValueSkillCoachById(237) }}
              </div></b-col
            ></b-row
          >
          <b-row align-v="center" class="mb-2"
            ><b-col
              ><div
                :style="
                  'cursor:pointer;background:' +
                  getBgStatCoach(228, getValueSkillCoachById(228, true), true)
                "
                class="box-small"
                @click="showRangeGames(228, true)"
              >
                {{ getValueSkillCoachById(228, true) }}
              </div></b-col
            ><b-col cols="6">{{ getSkillCoachById(228).label }}</b-col
            ><b-col
              ><div
                :style="
                  'cursor:pointer;background:' +
                  getBgStatCoach(228, getValueSkillCoachById(228))
                "
                class="box-small"
                @click="showRangeGames(228)"
              >
                {{ getValueSkillCoachById(228) }}
              </div></b-col
            ></b-row
          >
          <b-row align-v="center" class="mb-2"
            ><b-col
              ><div
                :style="
                  'cursor:pointer;background:' +
                  getBgStatCoach(115, getValueSkillCoachById(115, true), true)
                "
                class="box-small"
                @click="showRangeGames(115, true)"
              >
                {{ getValueSkillCoachById(115, true) }}
              </div></b-col
            ><b-col cols="6">{{ getSkillCoachById(115).label }}</b-col
            ><b-col
              ><div
                :style="
                  'cursor:pointer;background:' +
                  getBgStatCoach(115, getValueSkillCoachById(115))
                "
                class="box-small"
                @click="showRangeGames(115)"
              >
                {{ getValueSkillCoachById(115) }}
              </div></b-col
            ></b-row
          >
          <b-row align-v="center" class="mb-2"
            ><b-col
              ><div
                :style="
                  'cursor:pointer;background:' +
                  getBgStatCoach(318, getValueSkillCoachById(318, true), true)
                "
                class="box-small"
                @click="showRangeGames(318, true)"
              >
                {{ getValueSkillCoachById(318, true) }}
              </div></b-col
            ><b-col cols="6">{{ getSkillCoachById(318).label }}</b-col
            ><b-col
              ><div
                :style="
                  'cursor:pointer;background:' +
                  getBgStatCoach(318, getValueSkillCoachById(318))
                "
                class="box-small"
                @click="showRangeGames(318)"
              >
                {{ getValueSkillCoachById(318) }}
              </div></b-col
            ></b-row
          >
          <b-row align-v="center" class="mb-2"
            ><b-col
              ><div
                :style="
                  'cursor:pointer;background:' +
                  getBgStatCoach(160, getValueSkillCoachById(160, true), true)
                "
                class="box-small"
                @click="showRangeGames(160, true)"
              >
                {{ getValueSkillCoachById(160, true) }}
              </div></b-col
            ><b-col cols="6">{{ getSkillCoachById(160).label }}</b-col
            ><b-col
              ><div
                :style="
                  'cursor:pointer;background:' +
                  getBgStatCoach(160, getValueSkillCoachById(160))
                "
                class="box-small"
                @click="showRangeGames(160)"
              >
                {{ getValueSkillCoachById(160) }}
              </div></b-col
            ></b-row
          >
          <b-row align-v="center" class="mb-2"
            ><b-col
              ><div
                :style="
                  'cursor:pointer;background:' +
                  getBgStatCoach(200, getValueSkillCoachById(200, true), true)
                "
                class="box-small"
                @click="showRangeGames(200, true)"
              >
                {{ getValueSkillCoachById(200, true) }}
              </div></b-col
            ><b-col cols="6">{{ getSkillCoachById(200).label }}</b-col
            ><b-col
              ><div
                :style="
                  'cursor:pointer;background:' +
                  getBgStatCoach(200, getValueSkillCoachById(200))
                "
                class="box-small"
                @click="showRangeGames(200)"
              >
                {{ getValueSkillCoachById(200) }}
              </div></b-col
            ></b-row
          >
          <b-row align-v="center" class="mb-2"
            ><b-col
              ><div
                :style="
                  'cursor:pointer;background:' +
                  getBgStatCoach(289, getValueSkillCoachById(289, true), true)
                "
                class="box-small"
                @click="showRangeGames(289, true)"
              >
                {{ getValueSkillCoachById(289, true) }}
              </div></b-col
            ><b-col cols="6">{{ getSkillCoachById(289).label }}</b-col
            ><b-col
              ><div
                :style="
                  'cursor:pointer;background:' +
                  getBgStatCoach(289, getValueSkillCoachById(289))
                "
                class="box-small"
                @click="showRangeGames(289)"
              >
                {{ getValueSkillCoachById(289) }}
              </div></b-col
            ></b-row
          >
          <b-row align-v="center" class="mb-2"
            ><b-col
              ><div
                :style="
                  'cursor:pointer;background:' +
                  getBgStatCoach(56, getValueSkillCoachById(56, true), true)
                "
                class="box-small"
                @click="showRangeGames(56, true)"
              >
                {{ getValueSkillCoachById(56, true) }}
              </div></b-col
            ><b-col cols="6">{{ getSkillCoachById(56).label }}</b-col
            ><b-col
              ><div
                :style="
                  'cursor:pointer;background:' +
                  getBgStatCoach(56, getValueSkillCoachById(56))
                "
                class="box-small"
                @click="showRangeGames(56)"
              >
                {{ getValueSkillCoachById(56) }}
              </div></b-col
            ></b-row
          >
        </b-card>
      </b-col>
      <b-col cols="4" class="text-center">
        <h5>Player Ranking</h5>
        <b-card class="mb-2 pt-1" no-body>
          <h6>{{ getSkillById(5).label }}</h6>
          <b-row
            class="mb-2"
            v-for="item in ranking_data_away(5)"
            :key="item.id"
            ><b-col class="text-center">{{ item.player }}</b-col
            ><b-col class="text-center"
              ><div :style="'background:' + item.color" class="box-small">
                {{ item.value }}
              </div></b-col
            ></b-row
          >
        </b-card>
        <b-card no-body>
          <h6>{{ getSkillById(7).label }}</h6>
          <b-row
            class="mb-2"
            v-for="item in ranking_data_away(7)"
            :key="item.id"
            ><b-col class="text-center">{{ item.player }}</b-col
            ><b-col class="text-center"
              ><div :style="'background:' + item.color" class="box-small">
                {{ item.value }}
              </div></b-col
            ></b-row
          >
        </b-card>
      </b-col>
    </b-row>

    <b-row align-h="center">
      <b-col class="p-1 mb-2">
        <b-card no-body class="m-1 h-100 text-center">
          <a style="text-decoration: none"
            ><h2 class="mb-0 mt-1">PRESSING</h2></a
          >
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="text-center mb-2">
        <b-card>
          <p>
            {{
              game.team_home.squadra
                ? game.team_home.squadra.name
                : game.team_home.name
            }}
            Ball Recovery
          </p>
          <b-row class="px-5 mb-2">
            <b-col class="m-2 mr-4" style="border-bottom: 2px solid #ffffff">
              <h4>1</h4>
            </b-col>
            <b-col class="my-2" style="border-bottom: 2px solid #ffffff">
              <h4>2</h4>
            </b-col>
            <b-col class="m-2 ml-4" style="border-bottom: 2px solid #ffffff">
              <h4>3</h4>
            </b-col>
          </b-row>
          <b-row class="px-5 pb-4 mb-3">
            <b-col>
              <div
                :style="
                  'left:35%;cursor:pointer;background:' +
                  getBgStatCoach(40, getValueSkillCoachById(40, true), true)
                "
                class="zona-campo"
                @click="showRangeGames(40, true)"
              >
                {{ getValueSkillCoachById(40, true) }}
              </div>
            </b-col>
            <b-col>
              <div
                :style="
                  'left:35%;cursor:pointer;background:' +
                  getBgStatCoach(41, getValueSkillCoachById(41, true), true)
                "
                class="zona-campo"
                @click="showRangeGames(41, true)"
              >
                {{ getValueSkillCoachById(41, true) }}
              </div>
            </b-col>
            <b-col>
              <div
                :style="
                  'left:35%;cursor:pointer;background:' +
                  getBgStatCoach(42, getValueSkillCoachById(42, true), true)
                "
                class="zona-campo"
                @click="showRangeGames(42, true)"
              >
                {{ getValueSkillCoachById(42, true) }}
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div
                :style="
                  'cursor:pointer;background:' +
                  getBgStatCoach(43, getValueSkillCoachById(43, true), true)
                "
                class="zona-campo zona7"
                @click="showRangeGames(43, true)"
              >
                {{ getValueSkillCoachById(43, true) }}
              </div>
              <div
                :style="
                  'cursor:pointer;background:' +
                  getBgStatCoach(44, getValueSkillCoachById(44, true), true)
                "
                class="zona-campo zona8"
                @click="showRangeGames(44, true)"
              >
                {{ getValueSkillCoachById(44, true) }}
              </div>
              <div
                :style="
                  'cursor:pointer;background:' +
                  getBgStatCoach(45, getValueSkillCoachById(45, true), true)
                "
                class="zona-campo zona9"
                @click="showRangeGames(45, true)"
              >
                {{ getValueSkillCoachById(45, true) }}
              </div>
              <div
                :style="
                  'cursor:pointer;background:' +
                  getBgStatCoach(46, getValueSkillCoachById(46, true), true)
                "
                class="zona-campo zona10"
                @click="showRangeGames(46, true)"
              >
                {{ getValueSkillCoachById(46, true) }}
              </div>
              <div
                :style="
                  'cursor:pointer;background:' +
                  getBgStatCoach(47, getValueSkillCoachById(47, true), true)
                "
                class="zona-campo zona11"
                @click="showRangeGames(47, true)"
              >
                {{ getValueSkillCoachById(47, true) }}
              </div>
              <div
                :style="
                  'cursor:pointer;background:' +
                  getBgStatCoach(48, getValueSkillCoachById(48, true), true)
                "
                class="zona-campo zona12"
                @click="showRangeGames(48, true)"
              >
                {{ getValueSkillCoachById(48, true) }}
              </div>
              <div
                :style="
                  'cursor:pointer;background:' +
                  getBgStatCoach(49, getValueSkillCoachById(49, true), true)
                "
                class="zona-campo zona13"
                @click="showRangeGames(49, true)"
              >
                {{ getValueSkillCoachById(49, true) }}
              </div>
              <b-img :src="campoZoneImg" fluid></b-img>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <b-col class="text-center mb-2">
        <b-card>
          <p>
            {{
              game.team_away.squadra
                ? game.team_away.squadra.name
                : game.team_away.name
            }}
            Ball Recovery
          </p>
          <b-row class="px-5 mb-2">
            <b-col class="m-2 mr-4" style="border-bottom: 2px solid #ffffff">
              <h4>1</h4>
            </b-col>
            <b-col class="my-2" style="border-bottom: 2px solid #ffffff">
              <h4>2</h4>
            </b-col>
            <b-col class="m-2 ml-4" style="border-bottom: 2px solid #ffffff">
              <h4>3</h4>
            </b-col>
          </b-row>
          <b-row class="px-5 pb-4 mb-3">
            <b-col>
              <div
                :style="
                  'left:35%;cursor:pointer;background:' +
                  getBgStatCoach(40, getValueSkillCoachById(40))
                "
                class="zona-campo"
                @click="showRangeGames(40)"
              >
                {{ getValueSkillCoachById(40) }}
              </div>
            </b-col>
            <b-col>
              <div
                :style="
                  'left:35%;cursor:pointer;background:' +
                  getBgStatCoach(41, getValueSkillCoachById(41))
                "
                class="zona-campo"
                @click="showRangeGames(41)"
              >
                {{ getValueSkillCoachById(41) }}
              </div>
            </b-col>
            <b-col>
              <div
                :style="
                  'left:35%;cursor:pointer;background:' +
                  getBgStatCoach(42, getValueSkillCoachById(42))
                "
                class="zona-campo"
                @click="showRangeGames(42)"
              >
                {{ getValueSkillCoachById(42) }}
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div
                :style="
                  'cursor:pointer;background:' +
                  getBgStatCoach(43, getValueSkillCoachById(43))
                "
                class="zona-campo zona7"
                @click="showRangeGames(43)"
              >
                {{ getValueSkillCoachById(43) }}
              </div>
              <div
                :style="
                  'cursor:pointer;background:' +
                  getBgStatCoach(44, getValueSkillCoachById(44))
                "
                class="zona-campo zona8"
                @click="showRangeGames(44)"
              >
                {{ getValueSkillCoachById(44) }}
              </div>
              <div
                :style="
                  'cursor:pointer;background:' +
                  getBgStatCoach(45, getValueSkillCoachById(45))
                "
                class="zona-campo zona9"
                @click="showRangeGames(45)"
              >
                {{ getValueSkillCoachById(45) }}
              </div>
              <div
                :style="
                  'cursor:pointer;background:' +
                  getBgStatCoach(46, getValueSkillCoachById(46))
                "
                class="zona-campo zona10"
                @click="showRangeGames(46)"
              >
                {{ getValueSkillCoachById(46) }}
              </div>
              <div
                :style="
                  'cursor:pointer;background:' +
                  getBgStatCoach(47, getValueSkillCoachById(47))
                "
                class="zona-campo zona11"
                @click="showRangeGames(47)"
              >
                {{ getValueSkillCoachById(47) }}
              </div>
              <div
                :style="
                  'cursor:pointer;background:' +
                  getBgStatCoach(48, getValueSkillCoachById(48))
                "
                class="zona-campo zona12"
                @click="showRangeGames(48)"
              >
                {{ getValueSkillCoachById(48) }}
              </div>
              <div
                :style="
                  'cursor:pointer;background:' +
                  getBgStatCoach(49, getValueSkillCoachById(49))
                "
                class="zona-campo zona13"
                @click="showRangeGames(49)"
              >
                {{ getValueSkillCoachById(49) }}
              </div>
              <b-img :src="campoZoneImg" fluid></b-img>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row align-h="center">
      <b-col class="p-1 mb-2">
        <b-card no-body class="m-1 h-100 text-center">
          <a style="text-decoration: none"
            ><h2 class="mb-0 mt-1">WIDE PLAY</h2></a
          >
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="text-center mb-2">
        <b-card>
          <div>
            {{
              game.team_home.squadra
                ? game.team_home.squadra.name
                : game.team_home.name
            }}
            <br />
            Cross / Traversoni / Rimorchi
          </div>

          <b-row>
            <b-col>
              <div
                :style="
                  'right:11%; top: 15%; background:' +
                  getBgStatCoach(374, getValueSkillCoachById(374, true), true)
                "
                class="zona-campo-large"
              >
                {{ getValueSkillCoachById(374, true) }}
              </div>
              <div
                :style="
                  'right:11%; top: 28%; background:' +
                  getBgStatCoach(372, getValueSkillCoachById(372, true), true)
                "
                class="zona-campo-large"
              >
                {{ getValueSkillCoachById(372, true) }}
              </div>
              <div
                :style="
                  'right:11%; top: 50%; background:' +
                  getBgStatCoach(370, getValueSkillCoachById(370, true), true)
                "
                class="zona-campo-large"
              >
                {{ getValueSkillCoachById(370, true) }}
              </div>
              <div
                :style="
                  'left:11%; top: 15%; background:' +
                  getBgStatCoach(375, getValueSkillCoachById(375, true), true)
                "
                class="zona-campo-large"
              >
                {{ getValueSkillCoachById(375, true) }}
              </div>
              <div
                :style="
                  'left:11%; top: 28%; background:' +
                  getBgStatCoach(373, getValueSkillCoachById(373, true), true)
                "
                class="zona-campo-large"
              >
                {{ getValueSkillCoachById(373, true) }}
              </div>
              <div
                :style="
                  'left:11%; top: 50%; background:' +
                  getBgStatCoach(371, getValueSkillCoachById(371, true), true)
                "
                class="zona-campo-large"
              >
                {{ getValueSkillCoachById(371, true) }}
              </div>
              <dashed-field />
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <b-col class="text-center mb-2">
        <b-card>
          <div>
            {{
              game.team_away.squadra
                ? game.team_away.squadra.name
                : game.team_away.name
            }}
            <br />
            Cross / Traversoni / Rimorchi
          </div>
          <b-row>
            <b-col>
              <div
                :style="
                  'right:11%; top: 15%; background:' +
                  getBgStatCoach(374, getValueSkillCoachById(374))
                "
                class="zona-campo-large"
              >
                {{ getValueSkillCoachById(374) }}
              </div>
              <div
                :style="
                  'right:11%; top: 28%; background:' +
                  getBgStatCoach(372, getValueSkillCoachById(372))
                "
                class="zona-campo-large"
              >
                {{ getValueSkillCoachById(372) }}
              </div>
              <div
                :style="
                  'right:11%; top: 50%; background:' +
                  getBgStatCoach(370, getValueSkillCoachById(370))
                "
                class="zona-campo-large"
              >
                {{ getValueSkillCoachById(370) }}
              </div>
              <div
                :style="
                  'left:11%; top: 15%; background:' +
                  getBgStatCoach(375, getValueSkillCoachById(375))
                "
                class="zona-campo-large"
              >
                {{ getValueSkillCoachById(375) }}
              </div>
              <div
                :style="
                  'left:11%; top: 28%; background:' +
                  getBgStatCoach(373, getValueSkillCoachById(373))
                "
                class="zona-campo-large"
              >
                {{ getValueSkillCoachById(373) }}
              </div>
              <div
                :style="
                  'left:11%; top: 50%; background:' +
                  getBgStatCoach(371, getValueSkillCoachById(371))
                "
                class="zona-campo-large"
              >
                {{ getValueSkillCoachById(371) }}
              </div>
              <dashed-field />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row align-h="center">
      <b-col class="p-0 mb-2">
        <b-card no-body class="m-1 h-100 text-center">
          <a
            style="text-decoration: none"
            v-b-toggle
            href="#skill-coaches"
            @click.prevent
            ><h2 class="mb-0 mt-1">COACH SKILLS</h2></a
          >
        </b-card>
      </b-col>
    </b-row>

    <b-collapse id="skill-coaches">
      <b-table-simple small hover responsive striped>
        <b-tbody>
          <b-tr v-for="skill in coach_skills" :key="skill.id">
            <b-td class="text-center"
              ><div
                :style="
                  'cursor:pointer;background:' +
                  getBgStatCoach(
                    skill.id,
                    getValueSkillCoachById(skill.id, true),
                    true
                  )
                "
                class="box-small"
                @click="showRangeGames(skill.id, true)"
              >
                {{ getValueSkillCoachById(skill.id, true) }}
              </div></b-td
            >
            <b-td class="text-center">{{ skill.label }}</b-td>
            <b-td class="text-center">
              <div
                :style="
                  'cursor:pointer;background:' +
                  getBgStatCoach(skill.id, getValueSkillCoachById(skill.id))
                "
                class="box-small"
                @click="showRangeGames(skill.id)"
              >
                {{ getValueSkillCoachById(skill.id) }}
              </div>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-collapse>

    <b-row class="my-2">
      <b-col cols="3">
        <b-form-select
          v-model="filters.group"
          :options="groups"
          value-field="id"
          text-field="name"
        />
      </b-col>
      <b-col cols="3">
        <b-form-select
          v-model="filters.team"
          :options="teams"
          value-field="id"
          text-field="name"
        />
      </b-col>
      <b-col cols="6" class="text-right">
        <b-btn size="sm" variant="info" title="Info" v-b-toggle.collapse
          ><b-icon icon="info-circle"></b-icon
        ></b-btn>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-collapse id="collapse" class="mb-1">
          <b-row align-v="center" class="mt-2 mb-4">
            <b-col>
              <p>
                <strong>Micro basic</strong>: index calcolato sulle skills base
                (senza algoritmi) di tipo micro del gruppo ruolo di riferimento,
                senza normalizzare i valori tra 0 e 1, moltiplicate per i
                relativi pesi impostati sul gruppo ruolo (Micro/Weigth Player
                Basic Skill Setup)
              </p>
              <p>
                <strong>Macro basic</strong>: index calcolato su tutte le skills
                base (senza algoritmi), senza normalizzare i valori tra 0 e 1,
                moltiplicate per i relativi pesi impostati sul gruppo ruolo di
                riferimento (Micro/Weigth Player Basic Skill Setup)
              </p>
              <p>
                <strong>Micro raw</strong>: index calcolato sulle skills (con
                algoritmi) di tipo micro del gruppo ruolo di riferimento, senza
                normalizzare i valori tra 0 e 1, moltiplicate per i relativi
                pesi impostati sul gruppo ruolo (Micro/Weigth Player Setup)
              </p>
              <p>
                <strong>Macro raw</strong>: index calcolato su tutte le skills
                (con algoritmi), senza normalizzare i valori tra 0 e 1,
                moltiplicate per i relativi pesi impostati sul gruppo ruolo di
                riferimento (Micro/Weigth Player Setup)
              </p>
              <p>
                <strong>Micro</strong>: index calcolato sulle skills (con
                algoritmi) di tipo micro del gruppo ruolo di riferimento, con la
                normalizzare dei valori delle skilla tra 0 e 1 sui giocatori
                coinvolti nella partita, moltiplicate per i relativi pesi
                impostati sul gruppo ruolo (Micro/Weigth Player Setup)
              </p>
              <p>
                <strong>Macro</strong>: index calcolato su tutte le skills (con
                algoritmi), con la normalizzare dei valori delle skilla tra 0 e
                1 sui giocatori coinvolti nella partita, moltiplicate per i
                relativi pesi impostati sul gruppo ruolo di riferimento
                (Micro/Weigth Player Setup)
              </p>
              <p>
                <strong>Micro norm</strong>: index normalizzato tra 0 e 100 sui
                giocatori coinvolti nella partita, calcolato sulle skills (con
                algoritmi) di tipo micro del gruppo ruolo di riferimento, con la
                normalizzare dei valori delle skilla tra 0 e 1 sui giocatori
                coinvolti nella partita, moltiplicate per i relativi pesi
                impostati sul gruppo ruolo (Micro/Weigth Player Setup)
              </p>
              <p>
                <strong>Macro norm</strong>: index normalizzato tra 0 e 100 sui
                giocatori coinvolti nella partita, calcolato su tutte le skills
                (con algoritmi), con la normalizzare dei valori delle skilla tra
                0 e 1 sui giocatori coinvolti nella partita, moltiplicate per i
                relativi pesi impostati sul gruppo ruolo di riferimento
                (Micro/Weigth Player Setup)
              </p>
              <p>
                <strong>Micro group norm</strong>: index normalizzato tra 0 e
                100 sui giocatori dello stesso gruppo coinvolti nella partita,
                calcolato sulle skills (con algoritmi) di tipo micro del gruppo
                ruolo di riferimento, con la normalizzare dei valori delle
                skilla tra 0 e 1 sui giocatori coinvolti nella partita,
                moltiplicate per i relativi pesi impostati sul gruppo ruolo
                (Micro/Weigth Player Setup)
              </p>
              <p>
                <strong>Macro group norm</strong>: index normalizzato tra 0 e
                100 sui giocatori dello stesso gruppo coinvolti nella partita,
                calcolato su tutte le skills (con algoritmi), con la
                normalizzare dei valori delle skilla tra 0 e 1 sui giocatori
                coinvolti nella partita, moltiplicate per i relativi pesi
                impostati sul gruppo ruolo di riferimento (Micro/Weigth Player
                Setup)
              </p>
            </b-col>
          </b-row>
        </b-collapse>
      </b-col>
    </b-row>

    <b-table
      id="my-table"
      ref="table"
      sticky-header="600px"
      small
      striped
      dark
      responsive
      :fields="fields"
      :items="filteredItems"
      :tbody-tr-class="rowClass"
    >
      <template #cell(index)="data">
        <span
          :style="index_game_ids.includes(data.item.id) ? 'color:#000000' : ''"
        >
          {{ data.index + 1 }}
        </span>
      </template>
      <template #cell(player)="data">
        <span style="width: 200px; white-space: nowrap; overflow: hidden">
          <b-row>
            <b-col cols="1">
              <b-form-checkbox
                name="games_sel"
                v-model="index_game_ids"
                :value="data.item.id"
              >
              </b-form-checkbox>
            </b-col>
            <b-col>
              <router-link
                v-if="auth.isUserEnable('opta')"
                :to="{
                  name: 'ScoutingViewPlayer',
                  params: { id: data.value.id },
                }"
                target="_blank"
                style="cursor: pointer"
              >
                <span
                  :style="
                    index_game_ids.includes(data.item.id) ? 'color:#000000' : ''
                  "
                >
                  {{ data.value.player }}
                </span>
              </router-link>
              <span
                v-else
                :style="
                  index_game_ids.includes(data.item.id) ? 'color:#000000' : ''
                "
              >
                {{ data.value.player }}
              </span>
            </b-col>
          </b-row>
        </span>
      </template>
      <template #cell(team_id)="data">
        {{ getTeam(data.value) }}
      </template>
      <template #cell(date)="data">
        <span
          :style="index_game_ids.includes(data.item.id) ? 'color:#000000' : ''"
        >
          {{ data.value | formatDate }}
        </span>
      </template>
      <template #cell(micro_index_basic)="data">
        <div
          :style="
            'color:#000000;background:' + data.item['micro_index_basic_color']
          "
        >
          <strong>{{ Math.round(data.value * 100) / 100 }}</strong>
        </div>
      </template>
      <template #cell(macro_index_basic)="data">
        <div
          :style="
            'color:#000000;background:' + data.item['macro_index_basic_color']
          "
        >
          <strong>{{ Math.round(data.value * 100) / 100 }}</strong>
        </div>
      </template>
      <template #cell(micro_index_raw)="data">
        <div
          :style="
            'color:#000000;background:' + data.item['micro_index_raw_color']
          "
        >
          <strong>{{ Math.round(data.value * 100) / 100 }}</strong>
        </div>
      </template>
      <template #cell(macro_index_raw)="data">
        <div
          :style="
            'color:#000000;background:' + data.item['macro_index_raw_color']
          "
        >
          <strong>{{ Math.round(data.value * 100) / 100 }}</strong>
        </div>
      </template>
      <template #cell(micro_index)="data">
        <div
          :style="'color:#000000;background:' + data.item['micro_index_color']"
        >
          <strong>{{ Math.round(data.value * 100) / 100 }}</strong>
        </div>
      </template>
      <template #cell(macro_index)="data">
        <div
          :style="'color:#000000;background:' + data.item['macro_index_color']"
        >
          <strong>{{ Math.round(data.value * 100) / 100 }}</strong>
        </div>
      </template>
      <template #cell(micro_index_norm)="data">
        <div
          :style="
            'color:#000000;background:' + data.item['micro_index_norm_color']
          "
        >
          <strong>{{ Math.round(data.value * 100) / 100 }}</strong>
        </div>
      </template>
      <template #cell(macro_index_norm)="data">
        <div
          :style="
            'color:#000000;background:' + data.item['macro_index_norm_color']
          "
        >
          <strong>{{ Math.round(data.value * 100) / 100 }}</strong>
        </div>
      </template>
      <template #cell(micro_index_group_norm)="data">
        <div
          :style="
            'color:#000000;background:' +
            data.item['micro_index_group_norm_color']
          "
        >
          <strong>{{ Math.round(data.value * 100) / 100 }}</strong>
        </div>
      </template>
      <template #cell(macro_index_group_norm)="data">
        <div
          :style="
            'color:#000000;background:' +
            data.item['macro_index_group_norm_color']
          "
        >
          <strong>{{ Math.round(data.value * 100) / 100 }}</strong>
        </div>
      </template>
      <template #cell(group)="data">
        <span
          :style="index_game_ids.includes(data.item.id) ? 'color:#000000' : ''"
        >
          {{ data.value }}
        </span>
      </template>
      <template #cell(minutes)="data">
        <span
          :style="index_game_ids.includes(data.item.id) ? 'color:#000000' : ''"
        >
          {{ data.value }}
        </span>
      </template>
    </b-table>

    <div
      v-for="(array_princ, index_princ) in principles_skills"
      :key="'princ_' + index_princ"
    >
      <b-row align-h="center">
        <b-col class="p-0 mb-2">
          <b-card no-body class="m-1 h-100 text-center">
            <a
              style="text-decoration: none"
              v-b-toggle
              :href="'#monitoring-' + index_princ"
              @click.prevent
              ><h2 class="mb-0 mt-1">
                {{ principles_title[index_princ] }}
              </h2></a
            >
          </b-card>
        </b-col>
      </b-row>

      <b-collapse visible :id="'monitoring-' + index_princ">
        <b-row>
          <b-col
            sm="12"
            v-for="key in array_princ"
            :key="'line_' + key"
            class="p-0 mb-2"
          >
            <b-card class="m-1 h-100">
              <b-row v-if="skills.length > 0">
                <b-col cols="12" style="font-size: 0.85rem">
                  {{ getSkillById(key).label }}
                </b-col>
                <b-col cols="12">
                  <vertical-bar-ranking
                    v-if="ranking_data(key).length > 0"
                    :id="'rank_' + key"
                    :data="ranking_data(key)"
                  />
                </b-col>
                <b-col cols="12" class="mb-2">
                  <b-input-group prepend="Question">
                    <b-form-input
                      v-model="questions[key].question"
                      type="text"
                    ></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col cols="12" class="mb-2">
                  <b-input-group prepend="Domanda">
                    <b-form-input
                      v-model="questions[key].domanda"
                      type="text"
                    ></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col cols="6">
                  <div class="text-center">
                    <strong>{{ game.team_home.name }}</strong>
                  </div>
                  <b-form-checkbox-group
                    v-model="questions[key].players_home"
                    :name="'check_' + key"
                  >
                    <b-form-checkbox
                      v-for="player in players_home"
                      :value="player.id"
                      :key="key + '_ ' + player.id"
                      >{{ player.name }}</b-form-checkbox
                    >
                  </b-form-checkbox-group>
                </b-col>
                <b-col cols="6">
                  <div class="text-center">
                    <strong>{{ game.team_away.name }}</strong>
                  </div>
                  <b-form-checkbox-group
                    v-model="questions[key].players_away"
                    :name="'check_' + key"
                  >
                    <b-form-checkbox
                      v-for="player in players_away"
                      :value="player.id"
                      :key="key + '_ ' + player.id"
                      >{{ player.name }}</b-form-checkbox
                    >
                  </b-form-checkbox-group>
                </b-col>
                <b-col class="mt-3">
                  <b-button variant="primary">Save</b-button>
                </b-col>
                <!--b-col
                  cols="12"
                  class="mb-2"
                  :key="'ms_' + '_' + key + '_' + random"
                >
                  <multiselect
                    v-model="questions[key].players"
                    :multiple="true"
                    :options="players_options"
                    group-values="players"
                    group-label="team"
                    :group-select="true"
                    placeholder="Players"
                    label="name"
                    track-by="id"
                    selectLabel=""
                    deselectLabel=""
                    @input="onChange"
                  />
                </b-col-->
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-collapse>
    </div>
    <b-modal
      ref="skillModal"
      :title="
        show_range_skill_id ? getSkillCoachById(show_range_skill_id).label : ''
      "
    >
      <b-table
        small
        hover
        responsive
        striped
        :items="table_items"
        :fields="table_fields"
      >
        <template #cell(date)="data">
          {{ data.value | formatDate }}
        </template>
        <template #cell(skill)="data">
          <div
            :style="'background:' + bg_opta[data.item['index']]"
            class="box-small"
          >
            {{ Math.round(data.value * 100) / 100 }}
          </div>
        </template>
      </b-table>
    </b-modal>
  </b-container>
</template>

<script>
import moment from "moment";
import Auth from "@/lib/auth.js";
//import Multiselect from "vue-multiselect";
import loading from "@/assets/images/loading.gif";
import VerticalBarRanking from "@/components/charts/VerticalBarRanking.vue";
import campoZoneImg from "@/assets/images/campo_zone.png";
import DashedField from "@/assets/images/campo_dashed.svg";
export default {
  data: function () {
    return {
      ita: true,
      form: {
        //player_id: this.$route.params.id,
        game_id: this.$route.params.game_id,
        from: null,
        to: null,
        cluster_ids: [],
        group: null,
        minutes: 300,
        age_from: 0,
        age_to: 0,
      },
      groups: [
        { id: null, name: "-- Filter role group --" },
        { id: 0, name: "PORTIERI" },
        { id: 1, name: "DIFENSORI CENTRALI" },
        { id: 2, name: "ESTERNI" },
        { id: 3, name: "CENTROCAMPISTI CENTRALI" },
        { id: 4, name: "INTERNI DI CENTROCAMPO" },
        { id: 5, name: "ATTACCANTI CENTRALI" },
        { id: 6, name: "ATTACCANTI ESTERNI" },
      ],
      minutes: [300, 600, 900],
      loading,
      waiting: false,
      fields: [
        {
          key: "index",
          label: "",
        },
        {
          key: "player",
          sortable: true,
          label: "Player",
          thStyle: { "min-width": "150px" },
        },
        {
          key: "team_id",
          sortable: true,
          label: "Team",
          //    thStyle: { "min-width": "100px" },
        },
        {
          key: "micro_index_basic",
          sortable: true,
          label: "Micro basic",
          class: "text-center",
          thStyle: { "min-width": "70px", "max-width": "70px" },
        },
        {
          key: "macro_index_basic",
          sortable: true,
          label: "Macro basic",
          class: "text-center",
          thStyle: { "min-width": "70px", "max-width": "70px" },
        },
        {
          key: "micro_index_raw",
          sortable: true,
          label: "Micro raw",
          class: "text-center",
          thStyle: { "min-width": "70px", "max-width": "70px" },
        },
        {
          key: "macro_index_raw",
          sortable: true,
          label: "Macro raw",
          class: "text-center",
          thStyle: { "min-width": "70px", "max-width": "70px" },
        },
        {
          key: "micro_index",
          sortable: true,
          label: "Micro",
          class: "text-center",
          thStyle: { "min-width": "70px", "max-width": "70px" },
        },
        {
          key: "macro_index",
          sortable: true,
          label: "Macro",
          class: "text-center",
          thStyle: { "min-width": "70px", "max-width": "70px" },
        },
        {
          key: "micro_index_norm",
          sortable: true,
          label: "Micro norm",
          class: "text-center",
          thStyle: { "min-width": "70px", "max-width": "70px" },
        },
        {
          key: "macro_index_norm",
          sortable: true,
          label: "Macro norm",
          class: "text-center",
          thStyle: { "min-width": "70px", "max-width": "70px" },
        },
        {
          key: "micro_index_group_norm",
          sortable: true,
          label: "Micro group norm",
          class: "text-center",
          thStyle: { "min-width": "70px", "max-width": "70px" },
        },
        {
          key: "macro_index_group_norm",
          sortable: true,
          label: "Macro group norm",
          class: "text-center",
          thStyle: { "min-width": "70px", "max-width": "70px" },
        },
        { key: "group", sortable: true, label: "Group", class: "text-center" },
        { key: "minutes", sortable: true, label: "Min", class: "text-center" },
      ],
      waiting_index: false,
      bg_opta: [
        "#00B386",
        "#2EC85B",
        "#7BDE3C",
        "#B8F100",
        "#E9F700",
        "#FFF300",
        "#FFDB00",
        "#FFB800",
        "#FF8900",
        "#FF0059",
        "#FF9999",
      ],
      filter: null,
      indeterminate: false,
      player_index_games: [],
      team: null,
      index_game_ids: [],
      game: null,
      teams: [],
      filters: {
        group: null,
        team: null,
      },
      stats_range: null,
      skills: [],
      players: [],
      team_players: null,
      principles_title: [
        "PRESSING",
        "RECOVERY",
        "MARKING",
        "BUILD UP",
        "POSSESSION",
        "ENGAGEMENT",
        "UNMARKING",
        "DRIBBLING",
        "WIDE PLAY",
        "FINISHING",
        "CREATIVITY",
        "GOALKEEPER",
        "SET PLAY",
      ],
      principles_skills: [[], [], [], [], [], [], [], [], [], [], [], [], []],
      questions: {},
      random: 0,

      coach_stats_home: null,
      coach_stats_away: null,
      coach_stats_range_home: [],
      coach_stats_range_away: [],
      coach_skills: [],
      games_home: [],
      games_away: [],
      show_range_skill_id: null,
      table_fields: [
        { key: "game" },
        { key: "score" },
        { key: "date" },
        { key: "skill", sortable: true },
      ],
      table_items: [],
      campoZoneImg,
      lineup: null,
      substitute: null,
    };
  },

  components: {
    VerticalBarRanking,
    DashedField,
    //   Multiselect,
  },

  created: function () {
    this.getOptaTeam();
    this.getPlayerIndexGame();
    this.getSkillsPrinciples();
    this.getOptaTeamGameCoachesStats();
    //  this.getOptaTeamGamePlayersStats();
  },

  watch: {},

  methods: {
    getOptaTeam() {
      this.$http
        .get(
          "/opta/team/" +
            (this.auth.isUserEnable("teams_milan")
              ? "131"
              : this.$route.params.id)
        )
        .then((response) => {
          this.team = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getOptaTeamGamePlayersStats() {
      this.$http
        .get("/opta/team/game/players/stats/" + this.$route.params.game_id)
        .then((response) => {
          this.stats_range = response.data.stats;
          this.skills = response.data.skills;
          this.players = response.data.players;
          this.team_players = response.data.team_players;
          this.lineup = response.data.lineup;
          this.substitute = response.data.substitute;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getOptaTeamGameCoachesStats() {
      this.$http
        .get("/opta/team/game/coaches/stats/" + this.$route.params.game_id)
        .then((response) => {
          this.coach_stats_home = response.data.stats_home;
          this.coach_stats_away = response.data.stats_away;
          this.coach_stats_range_home = response.data.stats_range_home;
          this.coach_stats_range_away = response.data.stats_range_away;
          this.coach_skills = response.data.skills;
          this.games_home = response.data.games_home;
          this.games_away = response.data.games_away;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getSkillsPrinciples() {
      this.principles_skills = [
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
      ];
      this.$http.get("/opta/game/skills/principles").then((response) => {
        var principles = response.data;
        for (var i in principles) {
          var item = principles[i];
          this.principles_skills[item.principio].push(item.skill_id);
          this.questions[item.skill_id] = {
            question: "",
            domanda: "",
            players_home: [],
            players_away: [],
          };
        }
        this.getOptaTeamGamePlayersStats();
      });
    },

    getPlayerIndexGame() {
      if (
        this.form.game_id == null ||
        this.form.game_id === "" ||
        isNaN(this.form.game_id)
      ) {
        alert("Insert a valid player ID!");
        return;
      }
      this.waiting_index = true;
      this.player_index_games = [];
      this.index_game_ids = [];

      this.$http.post("/players/index/by/game", this.form).then((response) => {
        this.game = response.data.game;
        this.player_index_games = response.data.player_index_games;
        this.teams.push({ id: null, name: "-- Filter team --" });
        this.teams.push({
          id: this.game.team_home_id,
          name: this.game.team_home.name,
        });
        this.teams.push({
          id: this.game.team_away_id,
          name: this.game.team_away.name,
        });

        var array_micro_basic = [];
        var array_macro_basic = [];
        var array_micro_raw = [];
        var array_macro_raw = [];
        var array_micro = [];
        var array_macro = [];
        var array_micro_norm = [];
        var array_macro_norm = [];
        var array_micro_group_norm = [];
        var array_macro_group_norm = [];

        for (var i in this.player_index_games) {
          var item = this.player_index_games[i];
          array_micro_basic.push(item.micro_index_basic);
          array_macro_basic.push(item.macro_index_basic);
          array_micro_raw.push(item.micro_index_raw);
          array_macro_raw.push(item.macro_index_raw);
          array_micro.push(item.micro_index);
          array_macro.push(item.macro_index);
          array_micro_norm.push(item.micro_index_norm);
          array_macro_norm.push(item.macro_index_norm);
          array_micro_group_norm.push(item.micro_index_group_norm);
          array_macro_group_norm.push(item.macro_index_group_norm);
        }

        array_micro_basic.sort(function (a, b) {
          return b - a;
        });
        array_macro_basic.sort(function (a, b) {
          return b - a;
        });
        array_micro_raw.sort(function (a, b) {
          return b - a;
        });
        array_macro_raw.sort(function (a, b) {
          return b - a;
        });
        array_micro.sort(function (a, b) {
          return b - a;
        });
        array_macro.sort(function (a, b) {
          return b - a;
        });
        array_micro_norm.sort(function (a, b) {
          return b - a;
        });
        array_macro_norm.sort(function (a, b) {
          return b - a;
        });
        array_micro_group_norm.sort(function (a, b) {
          return b - a;
        });
        array_macro_group_norm.sort(function (a, b) {
          return b - a;
        });

        for (var i in this.player_index_games) {
          var item = this.player_index_games[i];

          var color_micro_index_basic = this.getBgStatIndex(
            item.micro_index_basic,
            array_micro_basic
          );
          item["micro_index_basic_color"] = color_micro_index_basic;

          var color_macro_index_basic = this.getBgStatIndex(
            item.macro_index_basic,
            array_macro_basic
          );
          item["macro_index_basic_color"] = color_macro_index_basic;

          var color_micro_index_raw = this.getBgStatIndex(
            item.micro_index_raw,
            array_micro_raw
          );
          item["micro_index_raw_color"] = color_micro_index_raw;

          var color_macro_index_raw = this.getBgStatIndex(
            item.macro_index_raw,
            array_macro_raw
          );
          item["macro_index_raw_color"] = color_macro_index_raw;

          var color_micro_index = this.getBgStatIndex(
            item.micro_index,
            array_micro
          );
          item["micro_index_color"] = color_micro_index;

          var color_macro_index = this.getBgStatIndex(
            item.macro_index,
            array_macro
          );
          item["macro_index_color"] = color_macro_index;

          var color_micro_index_norm = this.getBgStatIndex(
            item.micro_index_norm,
            array_micro_norm
          );
          item["micro_index_norm_color"] = color_micro_index_norm;

          var color_macro_index_norm = this.getBgStatIndex(
            item.macro_index_norm,
            array_macro_norm
          );
          item["macro_index_norm_color"] = color_macro_index_norm;

          var color_micro_index_group_norm = this.getBgStatIndex(
            item.micro_index_group_norm,
            array_micro_group_norm
          );
          item["micro_index_group_norm_color"] = color_micro_index_group_norm;

          var color_macro_index_group_norm = this.getBgStatIndex(
            item.macro_index_group_norm,
            array_macro_group_norm
          );
          item["macro_index_group_norm_color"] = color_macro_index_group_norm;
        }

        this.waiting_index = false;
      });
    },

    getBgStatIndex(value, array_range) {
      if (!isNaN(value)) {
        value = parseFloat(value);
        var count_array = array_range ? array_range.length : 0;
        var interval = count_array > 10 ? Math.floor(count_array / 10) : 1;
        var index = 0;
        for (var i = 0; i <= count_array - 1; i += interval) {
          var to = i + interval;
          if (i + interval > count_array - 1) {
            to = count_array - 1;
          }
          if (index == 0 && value > parseFloat(array_range[i])) {
            return this.bg_opta[index];
          }
          if (index == 9 && value < parseFloat(array_range[to])) {
            return this.bg_opta[index];
          }
          if (
            value <= parseFloat(array_range[i]) &&
            value >= parseFloat(array_range[to])
          ) {
            return this.bg_opta[index > 9 ? 9 : index];
          }
          index++;
        }

        return this.bg_opta[9];
      }
      return "#FFFFFF";
    },

    onFiltered(filteredItems) {},

    rowClass(item, type) {
      if (!item || type !== "row") {
        return "";
      }
      if (this.index_game_ids.includes(item.id)) {
        return "table-success";
      }
      return "";
    },

    getTeam(team_id) {
      var team = "";
      if (this.game && this.game.team_home_id == team_id) {
        team = this.game.team_home.name;
      } else if (this.game && this.game.team_away_id == team_id) {
        team = this.game.team_away.name;
      }
      return team;
    },
    getSkillById(id) {
      return this.skills.find((skill) => skill.id == id);
    },
    ranking_data(skill_id) {
      var data = [];
      var array_range = this.stats_range[skill_id];
      var skill = this.getSkillById(skill_id);
      /* if (skill.reverse) {
        array_range = this.stats_range[skill_id].slice().reverse();
      } */
      for (var i in array_range) {
        var item = array_range[i];
        var player = this.getPlayerById(item.player_id);
        data.push({
          score: item.skill_value_norm,
          color: this.getBgStat(skill_id, item.skill_value_norm),
          player: parseInt(i) + 1 + " - " + player.name,
          avatar: player.avatar ? player.avatar : "/assets/images/profile.png",
          colorLabel: "#FFFFFF",
          //value: item.skill_value_not_90,
          value: item.skill_value,
          //rank: parseInt(i),
        });
      }
      return data;
    },

    ranking_data_home(skill_id, size = 3) {
      var data = [];
      var array_range = this.stats_range[skill_id];
      var skill = this.getSkillById(skill_id);
      for (var i in array_range) {
        var item = array_range[i];
        var player = this.players_home.find((p) => p.id == item.player_id);
        if (player) {
          data.push({
            score: item.skill_value_norm,
            color: this.getBgStat(skill_id, item.skill_value_norm),
            player: parseInt(i) + 1 + " - " + player.name,
            avatar: player.avatar
              ? player.avatar
              : "/assets/images/profile.png",
            colorLabel: "#FFFFFF",
            value: item.skill_value,
          });
        }
        if (data.length == size) {
          return data;
        }
      }
      return data;
    },

    ranking_data_away(skill_id, size = 3) {
      var data = [];
      var array_range = this.stats_range[skill_id];
      var skill = this.getSkillById(skill_id);
      for (var i in array_range) {
        var item = array_range[i];
        var player = this.players_away.find((p) => p.id == item.player_id);
        if (player) {
          data.push({
            score: item.skill_value_norm,
            color: this.getBgStat(skill_id, item.skill_value_norm),
            player: parseInt(i) + 1 + " - " + player.name,
            avatar: player.avatar
              ? player.avatar
              : "/assets/images/profile.png",
            colorLabel: "#FFFFFF",
            value: item.skill_value,
          });
        }
        if (data.length == size) {
          return data;
        }
      }
      return data;
    },

    getPlayerById(id) {
      return this.players.find((player) => player.id == id);
    },
    getBgStat(skill_id, value) {
      if (!isNaN(value)) {
        value = parseFloat(value);
        var skill = this.getSkillById(skill_id);
        var array_range = this.stats_range[skill_id];
        var count_array = array_range ? array_range.length : 0;
        var interval = count_array > 10 ? Math.floor(count_array / 10) : 1;
        var index = 0;
        /*   if (skill.reverse) {
          for (var i = count_array - 1; i >= 0; i -= interval) {
            var to = i - interval;
            if (i - interval < 0) {
              to = 0;
            }
            if (index == 0 && value < parseFloat(array_range[i].skill_value)) {
              return this.bg_opta[index];
            }
            if (index == 9 && value > parseFloat(array_range[to].skill_value)) {
              return this.bg_opta[index];
            }
            if (
              value >= parseFloat(array_range[i].skill_value) &&
              value <= parseFloat(array_range[to].skill_value)
            ) {
              return this.bg_opta[index > 9 ? 9 : index];
            }
            index++;
          }
        } else { */
        for (var i = 0; i <= count_array - 1; i += interval) {
          var to = i + interval;
          if (i + interval > count_array - 1) {
            to = count_array - 1;
          }
          if (
            index == 0 &&
            value > parseFloat(array_range[i].skill_value_norm)
          ) {
            return this.bg_opta[index];
          }
          if (
            index == 9 &&
            value < parseFloat(array_range[to].skill_value_norm)
          ) {
            return this.bg_opta[index];
          }
          if (
            value <= parseFloat(array_range[i].skill_value_norm) &&
            value >= parseFloat(array_range[to].skill_value_norm)
          ) {
            return this.bg_opta[index > 9 ? 9 : index];
          }
          index++;
        }
        //   }
        return this.bg_opta[9];
      }
      return "#FFFFFF";
    },

    getSkillCoachById(id) {
      return this.coach_skills.find((skill) => skill.id == id);
    },

    getValueSkillCoachById(id, home = false) {
      var data = home ? this.coach_stats_home : this.coach_stats_away;
      if (typeof data[id] !== "undefined") {
        return Math.round(data[id] * 100) / 100;
      }
      return "-";
    },

    getBgStatCoach(skill_id, value, home = false) {
      if (!isNaN(value)) {
        value = parseFloat(value);
        var skill = this.getSkillCoachById(skill_id);
        var array_range = null;
        if (home) {
          array_range = this.coach_stats_range_home[skill_id]
            ? this.coach_stats_range_home[skill_id]
            : null;
        } else {
          array_range = this.coach_stats_range_away[skill_id]
            ? this.coach_stats_range_away[skill_id]
            : null;
        }
        if (array_range) {
          var count_array = array_range.length;
          var interval = count_array > 10 ? Math.floor(count_array / 10) : 1;
          var index = 0;
          if (skill.reverse) {
            for (var i = count_array - 1; i >= 0; i -= interval) {
              var to = i - interval;
              if (i - interval < 0) {
                to = 0;
              }
              if (
                index == 0 &&
                value <= parseFloat(array_range[i].skill_value)
              ) {
                return this.bg_opta[index];
              }
              if (
                index == 9 &&
                value >= parseFloat(array_range[to].skill_value)
              ) {
                return this.bg_opta[index];
              }
              if (
                value >= parseFloat(array_range[i].skill_value) &&
                value < parseFloat(array_range[to].skill_value)
              ) {
                return this.bg_opta[index > 9 ? 9 : index];
              } else if (
                i == to &&
                value == parseFloat(array_range[i].skill_value)
              ) {
                return this.bg_opta[index];
              }
              index++;
            }
          } else {
            for (var i = 0; i <= count_array - 1; i += interval) {
              var to = i + interval;
              if (i + interval > count_array - 1) {
                to = count_array - 1;
              }
              if (
                index == 0 &&
                value >= parseFloat(array_range[i].skill_value)
              ) {
                return this.bg_opta[index];
              }
              if (
                index == 9 &&
                value <= parseFloat(array_range[to].skill_value)
              ) {
                return this.bg_opta[index];
              }
              if (
                value <= parseFloat(array_range[i].skill_value) &&
                value > parseFloat(array_range[to].skill_value)
              ) {
                return this.bg_opta[index > 9 ? 9 : index];
              } else if (
                i == to &&
                value == parseFloat(array_range[i].skill_value)
              ) {
                return this.bg_opta[index];
              }
              index++;
            }
          }
          return this.bg_opta[9];
        }
      }
      return "#FFFFFF";
    },

    onChange(value) {
      this.random = Math.random();
    },

    showRangeGames(skill_id, home = false) {
      this.table_items = [];
      this.show_range_skill_id = skill_id;
      var range = [];
      var games = [];
      var skill = this.getSkillCoachById(skill_id);
      if (home) {
        games = this.games_home;
        range = this.coach_stats_range_home;
      } else {
        games = this.games_away;
        range = this.coach_stats_range_away;
      }
      for (var i in games) {
        var game = games[i];
        var index = range[skill_id]
          ? range[skill_id].findIndex((obj) => obj.game_id == game.id)
          : -1;
        var item = {
          index: skill.reverse ? 9 - index : index,
          game: game.label,
          score: game.score_home + " - " + game.score_away,
          date: game.date,
          skill: range[skill_id]
            ? range[skill_id].find((obj) => obj.game_id == game.id)
              ? range[skill_id].find((obj) => obj.game_id == game.id)
                  .skill_value
              : "-"
            : "-",
        };
        this.table_items.push(item);
      }
      this.$refs.skillModal.show();
    },
  },

  computed: {
    auth: function () {
      return Auth;
    },

    filteredItems: function () {
      var filtered = this.player_index_games;
      if (this.filters.team) {
        filtered = filtered.filter((item) => item.team_id == this.filters.team);
      }
      if (this.filters.group !== null) {
        filtered = filtered.filter((item) => item.group == this.filters.group);
      }
      return filtered;
    },

    /*  players_options: function () {
      var options = [];

      var team_home = this.game.team_home;

      var players_home = [];

      for (var i in this.players) {
        if (this.team_players[this.players[i].id] == team_home.id) {
          players_home.push(this.players[i]);
        }
      }
      options.push({
        team: team_home.name,
        players: players_home,
      });

      var team_away = this.game.team_away;

      var players_away = [];

      for (var i in this.players) {
        if (this.team_players[this.players[i].id] == team_away.id) {
          players_away.push(this.players[i]);
        }
      }
      options.push({
        team: team_away.name,
        players: players_away,
      });

      return options;
    }, */

    players_home: function () {
      var team_home = this.game.team_home;

      var players_home = [];

      for (var i in this.players) {
        if (this.team_players[this.players[i].id] == team_home.id) {
          players_home.push(this.players[i]);
        }
      }
      return players_home;
    },

    players_away: function () {
      var team_away = this.game.team_away;

      var players_away = [];

      for (var i in this.players) {
        if (this.team_players[this.players[i].id] == team_away.id) {
          players_away.push(this.players[i]);
        }
      }
      return players_away;
    },
  },

  filters: {
    formatDate: function (date) {
      var m = moment(date);
      if (m.isValid()) {
        return m.format("DD/MM/YYYY");
      }
      return "";
    },
  },
};
</script>
